import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_supply_chain()
})

$(document).scroll(function() {
  dhsv_vc_animate_plane()
})

function dhsv_vc_animate_plane() {
  $('.supply_chain').each(function() {
    var $this = $(this)
    let line = $this.find('.line')

    var lineOffset = window.pageYOffset

    line.height(lineOffset - 800)
  })
}

function dhsv_vc_supply_chain() {
  $('.supply_chain').each(function() {
    let $this = $(this)
    let $list = $this.find('.list_element').clone()
    $this.find('.list_element').remove()
    $list.appendTo($this.find('.supply_chain__list'))
    $this.find('.list_element:first-of-type').addClass('active')
    $this.find('.supply_chain__item:first-of-type').addClass('active')

    $this.on('click', '.list_element', function() {
      if (!$(this).hasClass('active')) {
        let target = $(this).data('item')
        $this.find('.list_element').removeClass('active')
        $this
          .find('.supply_chain__item')
          .hide()
          .removeClass('active')
        $(this).addClass('active')
        $this
          .find('.supply_chain__item[data-content=' + target + ']')
          .fadeIn()
          .addClass('active')
      }
    })
  })
}
