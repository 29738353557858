import cssVars from 'css-vars-ponyfill'
import { getCssVariable } from '../util/functional'
import WOW from 'wow.js'
import 'slick-carousel'
import Cookies from 'js-cookie'

export default {
  headerHeight: getCssVariable('--header-height'),
  body: $('body'),
  init() {
    // JavaScript to be fired on all pages
    this.setBodyWidth()
    this.megaMenuHandlers()
    this.stickyBanner()
    this.onResize()
    this.wowAnimation()
    this.infoBar()
    this.backToTop()
    this.languages()
    this.dhsv_vc_supply_chain()
    this.mobileSlider()
    this.svg_viewbox() //hide empty space around svg (single image)
    this.dhsv_headline_check()
    this.dhsv_remove_cookie_on_website_leave()
    this.dhsv_change_gform_footer_position()
    this.dhsv_gform_select_styling()
    this.dhsv_check_browser()

    this.body.on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.currentTarget).attr('href'), e)
      }
    )
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    this.onWindowLoad()
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
    })
  },
  megaMenuHandlers() {
    const { body: $body } = this
    const openedClass = 'megamenu-opened'
    const headerMenu = $('.nav-primary')
    $(
      '.ubermenu-item-has-children.ubermenu-item-level-0 > a',
      $body
    ).mouseenter(function() {
      if (!$body.hasClass(openedClass)) $body.addClass(openedClass)
    })

    headerMenu.mouseleave(function() {
      if ($body.hasClass(openedClass)) $body.removeClass(openedClass)
    })
  },
  onWindowLoad() {
    $(window).on('load', () => {
      // Fire action on window load

      if (window.location.hash) {
        this.smoothScroll(window.location.hash)
      }
    })
  },
  setBodyWidth() {
    cssVars({ variables: { '--body-width': document.body.clientWidth + 'px' } })
  },
  stickyBanner() {
    let scrollTop
    const body = $('body')
    const distance = 100

    function handleScroll() {
      scrollTop = $(window).scrollTop()

      if (scrollTop < distance) {
        body.removeClass('sticky-nav')
      } else {
        body.addClass('sticky-nav')
      }
    }

    handleScroll()
    $(window).on('scroll', this.throttled(10, handleScroll))
  },
  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  smoothScroll(link, e) {
    if (e) e.preventDefault()
    let hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      const headerHeight = parseFloat(this.headerHeight) || 0
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top - headerHeight,
        },
        600
      )
      history.replaceState(null, null, hash)
    }
    $(window).trigger('hashchange')
  },
  infoBar() {
    $('.js-close-infobar').on('click', e => {
      e.preventDefault()
      $('.js-infobar').slideUp()
      document.cookie = 'infobar_rejected=true'
    })
  },
  backToTop() {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 500) {
        $('.scrollToTop').fadeIn()
      } else {
        $('.scrollToTop').fadeOut()
      }
    })
    $('.scrollToTop').click(function() {
      $('html, body').animate({ scrollTop: 0 }, 1000)
      return false
    })
  },
  languages() {
    let $languageContainer = $('.header_languages')
    var currentLang = $languageContainer
      .find('.wpml-ls-current-language .wpml-ls-link')
      .html()
    $('.header_languages > span').html(currentLang)
    $('.header_languages > span').on('click', () => {
      $('.header_languages').addClass('active')
    })

    $(document).on('click', e => {
      if (
        !$languageContainer.is(e.target) &&
        $languageContainer.has(e.target).length === 0
      ) {
        $languageContainer.removeClass('active')
      }
    })
  },
  dhsv_vc_supply_chain() {
    $('.gform_wrapper').each(function() {
      let $this = $(this)
      if ($this.find('.gfmc-column').length && $(window).width() >= 768) {
        let $submit = $this.find('.gform_footer ').clone()
        $this.find('.gform_footer ').remove()
        $submit.appendTo($this.find('.gfmc-column:first-of-type'))
      }
    })
  },
  svg_viewbox() {
    $('.wpb_single_image').each(function() {
      if ($(this).find('svg').length) {
        let svg = $(this)
          .find('svg')
          .get(0)
        let bB = svg.getBBox()
        svg.setAttribute(
          'viewBox',
          bB.x + ',' + bB.y + ',' + bB.width + ',' + bB.height
        )
      }
    })
  },
  wowAnimation() {
    const wow = new WOW({
      boxClass: 'wpb_animate_when_almost_visible',
      animateClass: 'animated',
      offset: 250,
      mobile: false,
      live: true,
      scrollContainer: null,
      duration: 2000,
    })
    wow.init()
  },
  mobileSlider() {
    const viewport = window.bootstrapToolkit
    const mobileBreakpoint = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--sm')

    $('.js-mobile-slider').each(function() {
      const slider = $(this)
      const sliderArgs = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: parseInt(mobileBreakpoint) - 1,
            settings: {
              centerMode: true,
              centerPadding: '20px',
            },
          },
        ],
      }
      const sliderCheck = () => {
        if (viewport.is('<md')) {
          if (!slider.hasClass('slick-initialized')) {
            slider.slick(sliderArgs)
          }
        } else {
          if (slider.hasClass('slick-initialized')) {
            slider.slick('unslick')
          }
        }
      }

      sliderCheck()

      $(window).resize(
        viewport.changed(function() {
          sliderCheck()
        })
      )
    })
  },
  /*  dhsv_headline_check() {
    if (jQuery('body').hasClass('admin-logged-in')) {
      window.setInterval(function() {
        jQuery('*').each(function() {
          const tag = jQuery(this).prop('tagName')
          if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(tag)) {
            if (!jQuery(this).hasClass('headline-is-checked')) {
              jQuery(this).addClass('headline-is-checked')
              jQuery(this).wrap('<div style="background-color:pink;"></div>')
              jQuery(this).append(
                '<span style="background-color:greenyellow;">' + tag + '</span>'
              )
              const index = parseFloat(tag.replace('H', ''))
              const goIn = Array(index).fill('-')
              // eslint-disable-next-line
              console.log(
                goIn.join('') +
                  tag +
                  ' -> ' +
                  jQuery(this)
                    .text()
                    .trim()
              )
            }
          }
        })
      }, 2500)
    }
  },*/
  dhsv_remove_cookie_on_website_leave() {
    // check if the user wants to leave the page:
    $(document).bind('mouseleave', function(e) {
      if (e.pageY - $(window).scrollTop() <= 1) {
        // eslint-disable-next-line no-console
        //console.log('Tschüssikowski!')
        // set the cookie expired so its removed:
        Cookies.remove('borlabs-cookie')
      }
    })
  },
  dhsv_change_gform_footer_position() {
    if ($('#gform_fields_3').length) {
      var gform_footer_html = $('#gform_fields_3 li .gform_footer')
        .eq(0)
        .html()
      // eslint-disable-next-line no-console
      //console.log(gform_footer_html)
      $(gform_footer_html).appendTo('ul#gform_fields_3')
      $('#gform_fields_3 li .gform_footer')
        .eq(0)
        .remove()
    }
  },
  dhsv_gform_select_styling() {
    if ($('#gform_fields_3').length) {
      $('#gform_fields_3 select').on('click', function() {
        $(this).addClass('value-selected')
      })
      $('#gform_fields_3 select').on('change', function() {
        // eslint-disable-next-line no-console
        //console.log($(this).val())
        if ($(this).val() != '') {
          $(this).addClass('value-selected')
        } else {
          $(this).removeClass('value-selected')
        }
      })
    }
  },
  dhsv_check_browser() {
    let browser = ''
    let c = navigator.userAgent.search('Chrome')
    let f = navigator.userAgent.search('Firefox')
    let m8 = navigator.userAgent.search('MSIE 8.0')
    let m9 = navigator.userAgent.search('MSIE 9.0')
    if (c > -1) {
      browser = 'chrome'
    } else if (f > -1) {
      browser = 'firefox'
    } else if (m9 > -1) {
      browser = 'msie9'
    } else if (m8 > -1) {
      browser = 'msie8'
    }
    $('body').addClass(browser)
  },
}
