// logs its argument and a given tag
export const log = tag => x => {
  console.log(tag, x) // eslint-disable-line
  return x
}

export const getCssVariable = (name = '') => {
  return (
    getComputedStyle(document.documentElement).getPropertyValue(name) || null
  )
}
