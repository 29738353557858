import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_tooltip_image()
})

function dhsv_vc_tooltip_image() {
  $('.dhsv_vc_tooltip_image').each(function() {
    let $container = $(this)
    $container.on('click', '.point', function() {
      $(this).toggleClass('active')
    })

    $(document).on('click', e => {
      if (!$container.is(e.target) && $container.has(e.target).length === 0) {
        $container.find('.point').removeClass('active')
      }
    })
  })
}
