import $ from 'jquery'
import * as C2S from 'canvas2svg'
import * as DrawSVG from './drawSVGPlugin.min'
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/all' //eslint-disable-line
import ScrollMagic from 'scrollmagic'
import './animation.gsap'

$(document).ready(function() {
  dhsv_vc_timeline()
})

$(window).resize(function() {
  dhsv_vc_timeline()
})

function dhsv_vc_timeline() {
  $('.dhsv_vc_timeline').each(function() {
    const $timeLine = $(this)
    let spotPositions = new Array()
    $('.js-spot', $timeLine).each(function(index) {
      const $this = $(this)
      const position = $this.position()
      const topPosition = position.top + $this.height() / 2
      let leftPosition = parseFloat($this.css('marginLeft'))
      if (index % 2 === 0) {
        leftPosition = leftPosition + $this.outerWidth()
      }
      spotPositions = [
        ...spotPositions,
        { top: topPosition, left: leftPosition },
      ]
    })

    const $canvas = $('.js-canvas', $(this))
    const timeLineWidth = parseInt($timeLine.width())
    const timeLineHeight = parseInt($timeLine.height())
    // const c = $canvas[0]
    var ctx = new C2S(timeLineWidth, timeLineHeight)
    // var ctx = c.getContext('2d')
    // ctx.canvas.width = $canvas.width()
    // ctx.canvas.height = $canvas.height()
    ctx.lineWidth = 10
    ctx.strokeStyle = 'rgba(114, 189, 247, 0.5)'

    spotPositions.map((item, key) => {
      const prevItem = spotPositions[key - 1]
      let curve = 3

      spotPositions.map((el, i) => {
        let curveIndexes = [4, 5, 6]
        curveIndexes.map(index => {
          if (key === 10 * i + index) {
            curve = -3
          }
        })

        curveIndexes = [3, 7]
        curveIndexes.map(index => {
          if (key === 10 * i + index) {
            curve = 0
          }
        })

        curveIndexes = [2]
        curveIndexes.map(index => {
          if (key === 10 * i + index) {
            curve = 1.5
          }
        })

        curveIndexes = [8]
        curveIndexes.map(index => {
          if (key === 10 * i + index) {
            curve = -1.5
          }
        })
      })

      if (key === 0) {
        ctx.beginPath()
        ctx.moveTo(item.left, item.top)
      } else if (key === spotPositions.length - 1) {
        ctx.quadraticCurveTo(
          (item.left + prevItem.left) / 2 - curve,
          (item.top + prevItem.top) / 2,
          item.left,
          item.top
        )
        ctx.stroke()
        // ctx.closePath()
      } else {
        ctx.quadraticCurveTo(
          (item.left + prevItem.left) / 2 - curve,
          (item.top + prevItem.top) / 2,
          item.left,
          item.top
        )
      }
    })

    const svg = ctx.getSvg()
    $(svg).attr('viewBox', `0 0 ${timeLineWidth} ${timeLineHeight}`)
    $canvas.remove()
    if ($('svg', $(this)).length) $('svg', $(this)).remove()
    function makeSVG(tag, attrs) {
      var el = document.createElementNS('http://www.w3.org/2000/svg', tag)
      for (var k in attrs) {
        if (k == 'xlink:href') {
          el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', attrs[k])
        } else {
          el.setAttribute(k, attrs[k])
        }
      }
      return el
    }
    let attr = {
      xmlns: 'http://www.w3.org/2000/svg',
      width: 33,
      height: 33,
      class: 'airplain',
      style: 'transform: translateX(-100vw)',
    }
    attr['xlink:href'] =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAAACXBIWXMAAAsSAAALEgHS3X78AAAD4ElEQVRoQ+2a0XXTMBSGv/b0wW90g5oJKBM0eAGyQcMEBDQA6QA+hAnqbpAMgEg3IBNgJoA8obfyIDlxg23Ziqz40H7n5PQ0uj7xL91cXf3OycPDAz4RUuXAqnilSZQ3hFcipIqBETA2f+dpEs3qr+jOmS3AkWvzQkj1k91ELNIk+l11gZCqEDkGLqpifNKH8JzHN37BbiJuhVRrdhMRo4VeEZg+hNt4ZV7vbYF9cmoLcCC3BXRkAyxsQV3xLjxNognwAX3Dh3IPxGkSfbcFduXEd1UvMJV5gU5rFz6kSTS3BbnSm/ACIdUM+GSLK7EGJn2schnvqb6P2X9fowXZ+AKM+hYNAVa8QEh1DsyoruYb9Cp7L2J1BBNeIKRa8e++feO7M7PRe6oPlaEIj20BvnkW/tQ4hvDYFhCCYwjv/cjZhlOzvx6b2BbgCyFVLKTKzoBfQqo7YObilnii9ywQUo3QDdQV7M7j18C1kOoebfME66D6Rkg1AabsHZb2jYgr4MrYRXMgq7OKhoz5+k7Qgiuzqc6BuQA+AzMhVYbOgrwmdjCYo/AELfhFU+zJx69/2jbrS/QErGyBTQip6j7vpevkGsEzjMHZhi6e21vgrTEL52kSZZb4fxBSXTYMx3S0rfYLVhfO0PZOlwtfod3SObs6kDdfssXL1mkK1oRu912wAcanaRKN0AZAV16gnZUfQqrMspoHI6Q6F1JNzAOLW9xEr4HLNIlW2/O4MfQzLEXBwj06A7KqQZOa36rGgDdV9cNU6CktCpaFL2kSTYt/HhkR5kMWuM1mmcrt0CL8XXnCXApWDRtgur8Yj3r1NIl+m9S/4TCK7TA3X4PYEg+mbRVSjYRUC+AHh4teoz28bH+g1noyq7PgsPQqswS+U++4LtHF79BsK1iifbzKBqzRc/OY+qGxevKtzEbR3Rs/Fhta2tOthEMvqe+be2Dc9mzRWjhsUz9Dd3FDorM93Ul4gZBqiq7ax8b5QYSTcNj23QsCmAg1rNGpndsCq3AWDkdN/btUP4525iDhBQFTv7ILc8GLcAiS+l4fH3uzl80NXaI7Jt8sabk/t8XbipcxqT/Dz55v7cJc6EU4bFM/w/2nIK27MBe8pfo+5oZHwJ0ltIrefvRT0NuKlzFW0Zx2qd+5C3MhiHDYpv6KZvG9fJ+r6C3V9zFpa0vdVgcMHwQT3pLYFuCLoQkPxrPwp8bQhF/aAnwRWvjKMn5uGfdGaOGD4Vn4U+NZ+ED4b1tWW69uG/dGaOG5Zfz/FG5OaD8bQlYNY14JKtwwqXn/pu1zLx8EMyLKGEfmtvTWMk2icU14L/wFrP+O15jDdIIAAAAASUVORK5CYII='
    var airplain = makeSVG('image', attr)
    $(svg).append(airplain)
    $(this).prepend($(svg))

    gsap.registerPlugin(MotionPathPlugin)
    gsap.registerPlugin(DrawSVG)

    gsap.set($('svg .airplain', $(this))[0], {
      xPercent: -50,
      yPercent: 50,
      transformOrigin: '50% 50%',
    })

    var action = gsap
      .timeline({ defaults: { ease: 'none' } })
      .to($('svg .airplain', $(this))[0], { autoAlpha: 1, duration: 0.01 })
      .from($('svg path', $(this))[0], { drawSVG: 0, duration: 1 }, 0)
      .to(
        $('svg .airplain', $(this))[0],
        { duration: 1, motionPath: { path: $('svg path', $(this))[0] } },
        0
      )

    var Controller = new ScrollMagic.Controller()

    new ScrollMagic.Scene({
      triggerElement: $('svg', $(this))[0],
      duration: timeLineHeight,
      triggerHook: 0.5,
    })
      .setTween(action)
      .addTo(Controller)

    // const path = $('svg path', $(this))[0]
    // var pathLength = path.getTotalLength()
    // path.style.strokeDasharray = pathLength
    // path.style.strokeDashoffset = pathLength
    // path.getBoundingClientRect()
    //
    // $(window).scroll(function() {
    //   const scrollPos =
    //     $(window).scrollTop() +
    //     $(window).height() -
    //     $timeLine.offset().top -
    //     200
    //   const timeLinePos = $timeLine.outerHeight()
    //
    //   if (scrollPos >= 0) {
    //     var scrollPercentage = scrollPos / timeLinePos
    //     // Length to offset the dashes
    //     var drawLength = pathLength * scrollPercentage
    //
    //     // Draw in reverse
    //     path.style.strokeDashoffset = pathLength - drawLength
    //
    //     if (scrollPercentage >= 0.99) {
    //       path.style.strokeDasharray = 'none'
    //     } else {
    //       path.style.strokeDasharray = pathLength
    //     }
    //   }
    // })
  })
}
