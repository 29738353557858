const infoPosition = function() {
  $('.services-item', document).each(function() {
    const $this = $(this)
    const titleHeight = $('.services-item__title', $this).outerHeight(
      true,
      true
    )
    const serviceDescription = $('.service_description', $this)
    const hiddenDescription = $('.hidden_description', $this)

    serviceDescription.css('top', 'calc(100% - 30px - ' + titleHeight + 'px)')
    $('.services-item__info', $this).css({
      paddingBottom: titleHeight,
    })
    $this.css({
      minHeight: hiddenDescription.outerHeight() + titleHeight + 30,
    })
  })
}

$(window).on('load', () => {
  infoPosition()
})

$(window).on('resize', () => {
  infoPosition()
})
