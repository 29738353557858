import { WP_API_BASE } from '../../util/constants'
// import * as _ from 'lodash'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: [],
  states: {
    postsShowed: 0,
    postsLoaded: 0,
    perPageLoad: 9,
    allPostsLoaded: false,
  },
  pageCount: 0,
}

const LOAD_INSIGHTS_DATA = 'LOAD_INSIGHTS_DATA'
const LOAD_INSIGHTS_DATA_SUCCESS = 'LOAD_INSIGHTS_DATA_SUCCESS'
const LOAD_INSIGHTS_DATA_FAILURE = 'LOAD_INSIGHTS_DATA_FAILURE'
const SET_INSIGHTS_STATES = 'SET_INSIGHTS_STATES'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_INSIGHTS_DATA:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }

    case LOAD_INSIGHTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: [...payload.data],
        pageCount: payload.pageCount,
      }

    case LOAD_INSIGHTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case SET_INSIGHTS_STATES:
      return {
        ...state,
        states: { ...payload },
      }

    default:
      return state
  }
}

export const loadData = (
  currentPage,
  moduleType,
  selectedInsights
) => dispatch => {
  dispatch({ type: LOAD_INSIGHTS_DATA })

  // Rest API Params
  const params = {
    currentPage,
    moduleType,
    selectedInsights,
  }

  const format = window.siteLang.format
  let apiBaseUrl = WP_API_BASE
  if (parseInt(format) === 3) {
    params.lang = window.siteLang.current
  } else if (window.siteLang.current !== window.siteLang.default) {
    apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  }

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }
  return fetch(`${apiBaseUrl}insights${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(response => {
      let payload = {
        data: [...response.data],
        pageCount: response.pageCount,
      }

      dispatch({
        payload,
        type: LOAD_INSIGHTS_DATA_SUCCESS,
      })

      return response
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_INSIGHTS_DATA_FAILURE,
      })
    })
}

export const changeStates = states => dispatch => {
  return dispatch({
    type: SET_INSIGHTS_STATES,
    payload: states,
  })
}
