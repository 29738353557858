import $ from 'jquery'
import * as lottie from 'lottie-web'

let animations = []
let screenHeight
let animPos
let animHeight
let scrollPos

$(window).on('load', startAnim)

function getAnimParam(el) {
  screenHeight = document.documentElement.clientHeight
  animHeight = $(el.wrapper).outerHeight()
  animPos = $(el.wrapper).offset().top
}

function initAnim(el) {
  animHeight = $(el.wrapper).outerHeight()
  scrollPos = document.documentElement.scrollTop
  if (
    animPos - screenHeight + animHeight * 1 <= scrollPos &&
    !$(el.wrapper).hasClass('animated')
  ) {
    $(el.wrapper).addClass('animated')
    el.play()
  }
}

function startAnim() {
  $('.js-icon-animation').each(function(index, el) {
    animations.push(
      lottie.loadAnimation({
        container: el,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: $(el).data('animation'),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMin',
        },
      })
    )
  })

  animations.forEach(function(el) {
    getAnimParam(el)
    initAnim(el)

    $(window).on('resize', getAnimParam(el))
    $(document).on('scroll', function() {
      initAnim(el)
    })
  })
}
