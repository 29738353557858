import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import vacancies from './vacancies'
import insights from './insights'

const appReducer = combineReducers({
  browser,
  location: router,
  vacancies,
  insights,
})

export default appReducer
