import $ from 'jquery'

// const secondaryColors = [
//   '#e9945d',
//   '#9c9a8c',
//   '#ecb664',
//   '#a4d6d7',
//   '#8b80b1',
//   '#5964a8',
//   '#57b7a4',
//   '#b6cf93',
//   '#3e96a5',
//   '#df4a3e',
//   '#c2bfb6',
//   '#16aecd',
//   '#666',
// ]

const secondaryColors = [
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
  '#3e7ffa',
]

const randomColor = () => {
  const count = secondaryColors.length
  const index = Math.floor(Math.random() * count)
  return secondaryColors[index]
}

$(document).ready(function() {
  if ($(window).width() > 992) {
    dhsv_vc_features_circle()
  }
})

$(window).scroll(function() {
  var scrollTop = $(window).scrollTop(),
    winHeight = $(window).height()
  $('.dhsv_vc_features_circle').each(function() {
    var el = $(this),
      $items = el.find('.borders .item'),
      count = $items.length,
      i = 1,
      offsetTop = el.offset().top,
      height = el.height()

    if (scrollTop + winHeight > offsetTop + height) {
      if (el.attr('data-has-scrolled') !== 'true') {
        var refreshIntervalId = setInterval(function() {
          $items.removeClass('active')
          el.find('.borders .item:nth-child(' + i + ')').addClass('active')
          i++
          if (i === count + 2) {
            clearInterval(refreshIntervalId)
          }
        }, 150)
      }
      el.addClass('scrolled')
      el.attr('data-has-scrolled', 'true')
    } else {
      el.removeClass('scrolled')
    }
  })
})

function dhsv_vc_features_circle() {
  $('.dhsv_vc_features_circle').each(function() {
    var $el = $(this)
    var $items = $el.find('.borders .item')
    var $centerItems = $el.find('.item.center')
    var count = $items.length
    var $contents = $el.find('.circle_content_wrapper')
    var $circles = $items.find('circle')
    var active = 0

    $centerItems
      .toArray()
      .forEach(e => $(e).css({ backgroundColor: randomColor() }))
    $circles.toArray().forEach(e => $(e).attr('stroke', randomColor()))

    function makeItemActive(circle) {
      var parent = circle.parents('.item')
      const itemTitle = parent.data('title')
      const $title = circle.closest('.circle').find('.js-content-title')
      // const defaultTitle = $title.data('title')
      var $label = parent.find('.label')
      var key = parent.attr('data-key')
      $label.addClass('hover')
      $contents.find('.content_item').removeClass('active')
      $contents.find('.content_item[data-key="' + key + '"]').addClass('active')
      $items.removeClass('active')
      parent.addClass('active')
      $title
        .hide()
        .html(itemTitle)
        .fadeIn(200)
      active = key
      clearInterval(interval)
      interval = setInterval(loopInterval, 5000)
    }

    function loopInterval() {
      var circle = $el.find('.borders .item[data-key="' + active + '"] circle')
      makeItemActive(circle)
      if (active < count - 1) {
        active++
      } else {
        active = 0
      }
    }

    var interval = setInterval(loopInterval, 5000)

    $items.click(function() {
      var item = $(this)
      if (item.data('link') != '') {
        window.location.href = item.data('link')
      }
    })

    // $circles.click(function() {
    //   var key = parent.attr('data-key')
    //   $contents.find('.content_item').removeClass('active')
    //   $contents.find('.content_item[data-key="' + key + '"]').addClass('active')
    //   $items.removeClass('active')
    //   parent.addClass('active')
    //   const itemTitle = parent.data('title')
    //   const $title = $(this)
    //     .closest('.circle')
    //     .find('.js-content-title')
    //   $title
    //     .hide()
    //     .html(itemTitle)
    //     .fadeIn(200)
    //   active = key
    //   clearInterval(interval)
    //   interval = setInterval(loopInterval, 5000)
    // })

    $circles.hover(
      function() {
        makeItemActive($(this))
        // var parent = $(this).parents('.item')
        // const itemTitle = parent.data('title')
        // const $title = $(this)
        //   .closest('.circle')
        //   .find('.js-content-title')
        // // const defaultTitle = $title.data('title')
        // var $label = parent.find('.label')
        // var key = parent.attr('data-key')
        // $label.addClass('hover')
        // $contents.find('.content_item').removeClass('active')
        // $contents
        //   .find('.content_item[data-key="' + key + '"]')
        //   .addClass('active')
        // $items.removeClass('active')
        // parent.addClass('active')
        // $title
        //   .hide()
        //   .html(itemTitle)
        //   .fadeIn(200)
        // active = key
        // clearInterval(interval)
        // interval = setInterval(loopInterval, 5000)
      },
      function() {
        var parent = $(this).parents('.item')
        var $label = parent.find('.label')
        $label.removeClass('hover')
      }
    )
  })
}
