import $ from 'jquery'
import 'paginationjs'

$(document).ready(function() {
  dhsv_vc_image_gallery()
})

function dhsv_vc_image_gallery() {
  $('.dhsv_vc_image_gallery').each(function() {
    let arrayImages = $(this).data('srcs')
    $('.dhsv_vc_image_gallery__pagination', this).pagination({
      dataSource: arrayImages,
      pageSize: 1,
      pageRange: 1,
      totalNumber: 12,
      prevText: '<h6><i class="btb bt-arrow-left"></i></h6>',
      nextText: '<h6><i class="btb bt-arrow-right"></i></h6>',
      afterPaging: page => {
        $('.js-gallery-image', this).attr('src', arrayImages[page - 1])
      },
      beforePaging: () => {
        $('.js-gallery-image', this).addClass('is-hidden')
      },
    })

    $('.js-gallery-image', this).on('load', e => {
      $(e.target).removeClass('is-hidden')
    })
  })
}
