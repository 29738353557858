import { WP_API_BASE } from '../../util/constants'
// import * as _ from 'lodash'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: [],
  states: {
    postsShowed: 0,
    postsLoaded: 0,
    perPageLoad: 6,
    allPostsLoaded: false,
  },
  pageCount: 0,
}

const LOAD_VACANCIES_DATA = 'LOAD_VACANCIES_DATA'
const LOAD_VACANCIES_DATA_SUCCESS = 'LOAD_VACANCIES_DATA_SUCCESS'
const LOAD_VACANCIES_DATA_FAILURE = 'LOAD_VACANCIES_DATA_FAILURE'
const SET_VACANCIES_STATES = 'SET_VACANCIES_STATES'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_VACANCIES_DATA:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }

    case LOAD_VACANCIES_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: [...payload.data],
        pageCount: payload.pageCount,
      }

    case LOAD_VACANCIES_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case SET_VACANCIES_STATES:
      return {
        ...state,
        states: { ...payload },
      }

    default:
      return state
  }
}

export const loadData = (itemsPerPage, onlyFeatured, currentPage) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_VACANCIES_DATA })

  let currentPosts = [...getState().vacancies.data]
  if (currentPosts === undefined) {
    currentPosts = new Array()
  }

  // Rest API Params
  const params = {
    itemsPerPage,
    onlyFeatured,
    currentPage,
  }

  const format = window.siteLang.format
  let apiBaseUrl = WP_API_BASE
  if (parseInt(format) === 3) {
    params.lang = window.siteLang.current
  } else if (window.siteLang.current !== window.siteLang.default) {
    apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  }

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }

  return fetch(`${apiBaseUrl}vacancies${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(response => {
      let payload = {
        data: [...response.data],
        pageCount: response.pageCount,
      }

      dispatch({
        payload,
        type: LOAD_VACANCIES_DATA_SUCCESS,
      })

      return response
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_VACANCIES_DATA_FAILURE,
      })
    })
}

export const changeStates = states => dispatch => {
  return dispatch({
    type: SET_VACANCIES_STATES,
    payload: states,
  })
}
