import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_tooltip_map()
})

function dhsv_vc_tooltip_map() {
  $('.dhsv_vc_tooltip_map').each(function() {
    var $el = $(this)
    var $tooltips = $el.find('.dhsv_vc_tooltip_map__tooltip')
    var $map = $el.find('.dhsv_vc_tooltip_map__mapcontainer svg.map')

    $tooltips.hover(
      function() {
        var tooltip = $(this)
        var area = tooltip.attr('data-area')
        $map.addClass('active')
        $map.find('g.' + area).addClass('active')
      },
      function() {
        var tooltip = $(this)
        var area = tooltip.attr('data-area')
        $map.removeClass('active')
        $map.find('g.' + area).removeClass('active')
      }
    )
  })
}
